import React, { Component } from 'react'
import '../lists.scss';
import axios from 'axios';
import { dbURLBestAlbums, dbURLBestAlbumsLive } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import YoutubeEmbed from "../misc/YoutubeEmbed";
import ReactPlayer from "react-player";
import { Link } from 'react-router-dom';
import withRouter from '../withRouter';

class TheBestAlbums extends Component {

    state = {
        AlbumAllData: [],
        AlbumLiveData: [],
        loading: true
      }

    getAllAlbumData(){
        axios.get(dbURLBestAlbums + this.props.params.slug)
          .then(response => {
            this.setState({
              AlbumAllData: response.data,
              loading: false
            });
          });
      } 
    
     getLiveDataItems(){
        axios.get(dbURLBestAlbumsLive + this.props.params.slug)
          .then(response => {
            this.setState({
                AlbumLiveData: response.data
            });
          });
      }  
    
    componentDidMount() {
        this.getAllAlbumData()
        this.getLiveDataItems()
      }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };
      
    render() {

        const AllAlbumDetails = this.state.AlbumAllData;
        const AllAlbumLiveSongs = this.state.AlbumLiveData;
        //console.table(AllAlbumLiveSongs);
        let LiveHeading
        

        const AlbumDetails = AllAlbumDetails.map(value => {

            if (value.live_album) {
                LiveHeading = 'Other selected live versions';
            }
            else {
                LiveHeading = 'Selected live versions';
            }

            let charLimitLiner
            if (value.liner_best_albums) {
                charLimitLiner = value.liner_best_albums;
            }
            else {
                charLimitLiner = 1200;
            }

            let charLimitLineUp
            if (value.line_up_best_albums) {
                charLimitLineUp = value.line_up_best_albums;
            }
            else {
                charLimitLineUp = 800;
            }

            return (
                <React.Fragment key={value.id}>

                    <div className="row">

                        <div className="col-sm-5 mb-3">
                            
                            <img src={value.picture} className="img-fluid" alt=""></img>

                        </div>
                        <div className="col-sm-7 mb-3">

                            <div className='BestSong text-center p-1 mb-3 rounded'><b>{value.artist} - {value.title}</b>
                            </div>
                            
                            <div className="card bg-light py-2 px-2">
                                <div className="quote mb-3">{value.main_comment}</div> 
                                <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                            </div>

                            <div className='row'>

                                <div className='col-7'>
                                    <br /><b>Video/audio</b>
                                    <div className="border">
                                        <ReactPlayer
                                                url={ value.youtube }
                                                //light='true'
                                                //light
                                                controls
                                                width="500"
                                                height="250"
                                            />
                                    </div>
                                </div>
                                

                                <div className='col-5 mt-4'>
                                    {
                                        (() => {
                                        if (value.album_released) {
                                        return <div><b>Released</b> <br />
                                        <div className="Date p-1">
                                            -> <b>{value.album_released}</b></div></div>
                                        } else if (value.ar_text) {
                                        return <div><b>Released</b> <br />
                                        <div className="Date p-1">
                                            {value.ar_text}</div></div>
                                        }
                                        })()
                                    }
                                    <b>Recorded </b><br />
                                    {
                                        (() => {
                                        if (value.recorded) {
                                        return <div className="Date p-1"><i>{value.recorded}</i></div>
                                        }
                                        return <div className="Date p-1"><i>{value.rd_text}</i></div>
                                        })()
                                    }

                                    {
                                        (() => {
                                        if (value.studio_txt) {
                                        return <div>
                                                    <b>Studio</b> <br />
                                                    <div className="factText p-1">{value.studio_txt}</div>
                                                    
                                                </div>
                                        } 
                                        return 
                                        })()
                                    }
                                    
                                </div>
                                
                            </div>

                            

                        </div>

                    </div>

                    <div className="row pt-2 pb-3 border-bottom border-top border-info">

                        {/* kolonne 1 */}
                        <div className="col-sm-8 pb-3 border-right">

                                

                                <div className="pt-3 pb-3 Description">
                                    <div className='factText4 p-1'>
                                        <ReadMoreAndLess
                                                charLimit={value.desc_best_albums|1200}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                        <div className='pt-1 float-right'>
                                            <em> -> <a href={value.wiki}>wikipedia..</a></em>
                                         </div>
                                    </div>
                                    
                                </div>

                                {
                                    (() => {
                                    if (value.liner_notes) {
                                    return <div>
                                                <b>Liner notes</b><br />
                                                <div className="pb-1 Description">
                                                    <div className='factText p-1'>
                                                        <ReadMoreAndLess
                                                                charLimit={charLimitLiner}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.liner_notes}
                                                        </ReadMoreAndLess>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                    } 
                                    return 
                                    })()
                                }

                            

                        </div>
                        
                        {/* kolonne 2 */}
                        <div className="col-sm-4">

                                

                                <div className="row pt-2">
                                    <div className="col-12">

                                        {
                                            (() => {
                                            if (value.producer) {
                                            return <div>
                                                        <b>Producer</b> <br />
                                                        <div className="factText p-1">{value.producer}</div>
                                                        
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }
                                                
                                        {
                                            (() => {
                                            if (value.engineer) {
                                            return <div><b>Engineer</b> <br />
                                            <div className="factText p-1">{value.engineer}</div>
                                            
                                            </div>
                                        
                                            } 
                                            return <p></p>
                                            })()
                                        }

                                        <div>
                                        <b>Label</b> <br />
                                        <div className="factText p-1">{value.label}</div>
                                        </div>

                                        <div>
                                        <b>Genre</b> <br />
                                        <div className="factText p-1">{value.genre}</div>
                                        </div>  

                                        <div>
                                        <b>Length</b> <br />
                                        <div className="factText p-1">{value.length}</div>
                                        </div> 

                                    </div>
                                </div>

                                <div className="row pt-3">
                                    <div className="col-12">
                                        <b>Track listing</b><br />
                                        <div className="bg-light">
                                            <div className="py-1 px-1 Description">
                                                <ReadMoreAndLess
                                                        charLimit={600}
                                                        readMoreText={" Read more ▼"}
                                                        readLessText={" Read less ▲"}
                                                        readMoreClassName="read-more-less--more"
                                                        readLessClassName="read-more-less--less"
                                                    >
                                                    {value.track_listing}
                                                </ReadMoreAndLess>
                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row pt-3">
                                    <div className="col-12">
                                        {
                                            (() => {
                                            if (value.personnel) {
                                            return  <div><b>Musicians</b> <br />
                                                        <div className="factText px-1">
                                                            <ReadMoreAndLess
                                                                    charLimit={charLimitLineUp}
                                                                    readMoreText={" Read more ▼"}
                                                                    readLessText={" Read less ▲"}
                                                                    readMoreClassName="read-more-less--more"
                                                                    readLessClassName="read-more-less--less"
                                                                >
                                                                {value.personnel}
                                                            </ReadMoreAndLess>
                                                        </div>
                                                    </div>
                                            } 
                                            return 
                                            })()
                                        }
                                    </div>
                                </div>
                            
                            </div>
                                
                    </div>

                    {/* COMMENTS */}
                    <div className="row pt-3 pb-3 border-bottom border-top border-info">

                        <div className="col-sm-6">

                            <div className="card bg-light py-2 px-2">
                                <div className="quote mb-3">{value.comment}</div> 
                                <div className="blockquote-footer"><i>{value.source_txt} ({value.source_txt_details})</i></div>
                            </div>

                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="quote mb-3">{value.comment1}</div> 
                                <div className="blockquote-footer"><i>{value.source_1}</i></div>
                            </div>
                            {
                                (() => {
                                if (value.comment3) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment3}</div> 
                                                <div className="blockquote-footer"><i>{value.source_3}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }
                            
                            {
                                (() => {
                                if (value.comment5) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment5}</div> 
                                                <div className="blockquote-footer"><i>{value.source_5}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                        <div className="col-sm-6">

                            <div className="card bg-light py-2 px-2">
                                <div className="quote mb-3">{value.comment_2}</div> 
                                <div className="blockquote-footer"><i>{value.source_txt_2} ({value.source_txt_details_2})</i></div>
                            </div>

                            <div className="card bg-light mt-3 py-2 px-2">
                                <div className="quote mb-3">{value.comment2}</div> 
                                <div className="blockquote-footer"><i>{value.source_2}</i></div>
                            </div>

                            {
                                (() => {
                                if (value.comment4) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment4}</div> 
                                                <div className="blockquote-footer"><i>{value.source_4}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                            {
                                (() => {
                                if (value.comment6) {
                                    return <div>
                                            <div className="card bg-light mt-3 py-2 px-2">
                                                <div className="quote mb-3">{value.comment6}</div> 
                                                <div className="blockquote-footer"><i>{value.source_6}</i></div>
                                            </div>
                                        </div>
                                }
                                return 
                                })()
                            }

                        </div>

                    </div>

                    {/* Selected songs */}

                    {
                        (() => {
                        if (value.youtube1) {
                            return <div>
                                         <div className='row mt-3'>
                                            <div className='col'>
                                                <div className='BestSong text-center p-1 mb-1 rounded'><b>Selected songs</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-3 pb-3 border-bottom border-info">

                                            <div className="col-sm-6">

                                                <div className="card bg-light py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.youtube1} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.song1_txt}</b></h5>
                                                    </div>
                                                </div>

                                                <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.youtube3} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.song3_txt}</b></h5>
                                                    </div>
                                                </div>

                                                

                                            </div>

                                            <div className="col-sm-6">
                                                
                                                <div className="card bg-light py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.youtube2} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.song2_txt}</b></h5>
                                                    </div>
                                                </div>

                                                <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.youtube4} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.song4_txt}</b></h5>
                                                    </div>
                                                </div>
                                            

                                            </div>

                                        </div>
                                    
                                </div>
                        }
                        return 
                        })()
                    }

                   

                    {/* Selected outtakes */}
                    {
                        (() => {
                        if (value.outtake1) {
                            return <div>
                                        <div className='row mt-3'>
                                            <div className='col'>
                                                <div className='BestSong text-center p-1 mb-1 rounded'><b>Outtakes</b>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-1'>
                                            <div className='col'>
                                                <div className="pb-1 Description">
                                                    <div className='factText p-2'>
                                                        <ReadMoreAndLess
                                                                charLimit={1200}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.outtakes_description}
                                                        </ReadMoreAndLess>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="row pt-3 pb-3 border-bottom border-info">

                                            <div className="col-sm-6">

                                                <div className="card bg-light py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.video1} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.outtake1}</b></h5>
                                                    </div>
                                                </div>

                                                <div className="card bg-light mt-2 py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.video3} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.outtake3}</b></h5>
                                                    </div>
                                                </div>

                                                

                                            </div>

                                            <div className="col-sm-6">
                                                
                                                <div className="card bg-light py-2 px-2">
                                                    <div className="mx-4">  
                                                            <YoutubeEmbed embedId={value.video2} />
                                                    </div>
                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                        <h5><b>{value.outtake2}</b></h5>
                                                    </div>
                                                </div>

                                                {
                                                    (() => {
                                                    if (value.outtake4) {
                                                        return <div className="card bg-light mt-2 py-2 px-2">
                                                                    <div className="mx-4">  
                                                                            <YoutubeEmbed embedId={value.video4} />
                                                                    </div>
                                                                    <div className='DescriptionBig2 pl-4 mt-2'>
                                                                        <h5><b>{value.outtake4}</b></h5>
                                                                    </div>
                                                                </div>
                                                    }
                                                    return 
                                                    })()
                                                }

                                                
                                            

                                            </div>

                                        </div>




                                </div>
                        }
                        return 
                        })()
                    }

                </React.Fragment>          
            )

        }) 

        
        const LiveItems = AllAlbumLiveSongs.map(value => {

            let SongTitle
            if (value.title) {
                SongTitle = value.title
            }
            else {
                SongTitle = value.song_txt
            }
        
            return (

                <React.Fragment key={value.id}>

                    <div className="row videoTitle2">
                        <div className="col-sm-12"> 
                            <div>&nbsp;<b>{SongTitle} @ {value.venue} - {value.city}, {value.country} - {value.gig_date_clean}</b>
                                {
                                    (() => {
                                    if (value.last) {
                                        return <button type="button" className="btn btn-info btn-xs my-1" style={{float: 'right'}}>Last performance</button>
                                        
                                    }
                                    if (value.first) {
                                        return <button type="button" className="btn btn-info btn-xs my-1" style={{float: 'right'}}>Live debut</button>
                                    }
                                    return 
                                    })()
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3 pb-3">
                        <div className="col-sm-3">
                            {
                                (() => {
                                if (value.picture_link && !value.pic_size) {
                                    return <div><img src={value.picture_link} alt="" height="250" width="250" 
                                            className="rounded mx-auto d-block"></img><br /></div>
                                }
                                if (value.picture_link && value.pic_size) {
                                    return <div><img src={value.picture_link} alt="" height="335" width="250" 
                                            className="rounded mx-auto d-block"></img><br /></div>
                                }
                                return 
                                })()
                            }

                            <p><b>{value.tour}</b></p>
                            
                            

                        </div>

                        <div className="col-sm-4">

                            {
                                (() => {
                                if (value.selected_lyrics) {
                                    return <div className="bg-light mx-1">
                                                <div className="py-1 px-1 Description">
                                                    <ReadMoreAndLess
                                                            charLimit={700}
                                                            readMoreText={" Read more ▼"}
                                                            readLessText={" Read less ▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                        >
                                                        {value.selected_lyrics}
                                                    </ReadMoreAndLess>
                                            
                                                </div>
                                            </div>
                                }
                                if (value.comment) {
                                    return <div className="card bg-light py-2 px-2">
                                                <div className="quote mb-3">{value.comment}</div> 
                                                <div className="blockquote-footer"><i>{value.source}</i></div>
                                            </div>
                                }
                                return 
                                })()
                            }
                            
                            
                                
                                
                                <div className='mt-2'><b>Line-up:</b></div>
                                <div className="factText mb-2 px-2 py-1">{value.musicians}</div>

                             

                        </div>

                        <div className="col-sm-5">

                            <div className="Header2" align="center"><b>Audio/Video - click to play</b></div>

                                <div className="border my-2">  
                                       
                                        <YoutubeEmbed embedId={value.video} />
                                </div>
                                        
                        </div>

                    </div>

                </React.Fragment>
            
            )

        })
    
        return (
                <div>
                    {
                        (() => {
                        if (this.state.loading) {
                            return <div>
                                    <main className="my-4 py-4"><div className="container"> 
                                    <div className="row pt-5"><div className="col">
                                    <h4>Loading...</h4></div></div></div></main>;
                                </div>
                        }
                        if (AllAlbumDetails.length < 1) {
                            return <div>
                                        <main className="my-1 py-1">
                                            <div className="container">  
                                                <div className="row pt-1">
                                                    <div className="col">
                                                        <h1>404 - Not Found!</h1>
                                                        <Link to="/lists">
                                                            Go to current Posts
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                </div>
                        }
                        return <main className="my-1 py-1">
                                    <div className="container">
                    
                                        <div className="row bestSongsTitle mb-1">
                                            <div className="col-sm-12 mb-2"> 
                                                <div className="text-center text-light bg-secondary rounded"><b>Great Albums</b></div>
                                            </div>
                                        </div>
                    
                                        <div className="row">
                                            <div className="col">
                                                    {AlbumDetails}
                                            </div>
                                        </div>
                                        
                                        <div className='row mt-3'>
                                            <div className='col'>
                                                <div className='BestSong text-center p-1 mb-3 rounded'><b>{LiveItems.length} - {LiveHeading}</b>
                                                </div>
                                            </div>
                                        </div>
                    
                                        <div className="row">
                                            <div className="col">
                                                    {LiveItems}
                                            </div>
                                        </div>
                            
                    
                                        <p align="Right"><br />
                                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                                            <b>Scroll to top</b></button>
                                        </p>
                                        
                                
                                    </div>
                                </main>
                        })()
                    }
                    
                </div>

            

        )
    }
}

export default withRouter(TheBestAlbums)