import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURL } from './Constants';
import './lists.scss';
import SEO from "../SEO";
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
import {DebounceInput} from 'react-debounce-input';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import { SelectProperty, SearchLists } from "./misc/utils"
import ReactMarkdown from 'react-markdown';

const Lists = () => {

    const [listsData, setlistsData] = useState([])
    const [listsInProgressData, setlistsInProgressData] = useState([])
    const [loadingLists, setLoadingLists] = useState(true)
    const [loadingInProgressLists, setLoadingInProgressLists] = useState(true)
    const [errorLists, setErrorLists] = useState(null)
    const [errorInProgress, setErrorInProgress] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [optionRange, setOptionRange] = useState('All')
    const [optionArtist, setOptionArtist] = useState(null)
    const [AgGrid, setAgGrid] = useState(false)
    const [columnDefs] = useState([
            {
                field: 'page',
                headerName: 'List (click on name to open)',
                cellRenderer: function(params) {
                    let pageData = params.data.page;
                    let nameData = params.data.name;
                    let newLink = `<a href= "https://www.musicthisday.com/${pageData}">${nameData}</a>`;
                    return newLink;
                },
                sortable: true, width: 500, cellStyle: { 'font-size': '14px' }
                //flex: 1,
            },
            {headerName: "Category", field: "category", sortable: true, width: 100, cellStyle: { 'font-size': '14px' }},
            {headerName: "Source", field: "source", sortable: true, width: 250, cellStyle: { 'font-size': '14px' }},
            {headerName: "Year", field: "year", sortable: true, width: 100, cellStyle: { 'font-size': '14px' }}
          ])


    
   //console.log(optionArtist);

    let filterArray, ArtistOptions

    useEffect(() => {
        const fetchListsData = () => {
          axios.get(dbURL + 'lists')
          .then(response => {
            setLoadingLists(false)
            setlistsData(response.data)
          }).catch(errorLists => {
            setErrorLists(errorLists);
          });
        }

        const fetchInProgressData = () => {
            axios.get(dbURL + 'unfinishedlists')
            .then(response => {
                setLoadingInProgressLists(false)
                setlistsInProgressData(response.data)
            }).catch(errorInProgress => {
                setErrorInProgress(errorInProgress);
            });
        }

        fetchListsData()
        fetchInProgressData()
      }, [])
  
    if (errorLists) return `Error List: ${errorLists.message}`;
    if (errorInProgress) return `Error ListInProgress: ${errorInProgress.message}`;

    ArtistOptions = SelectProperty(listsData, 'artist')

    const selectedOptionArtist = (selectedArtist) => {
        setOptionArtist(selectedArtist);
    } 

    const checkHandler = () => {
        setAgGrid(!AgGrid)
      }

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    if (loadingLists || loadingInProgressLists) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
    const clearSearchField = (e) => {
        setSearchString('')
        setOptionRange('All')
        }

    if (searchString) {
        filterArray = SearchLists(listsData, 'name', searchString.toLowerCase());
        } 
    if (optionRange === 'People' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'People');  
        } 
    if (optionRange === 'Albums' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Albums');  
        } 
    if (optionRange === 'Songs' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Songs');  
        } 
    if (optionRange === 'Concerts' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Concerts');  
        } 
    if (optionRange === 'Interviews' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Interviews');  
        } 
    if (optionRange === 'Live Songs' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Live Songs');  
        } 
    if (optionRange === 'Timeline' && !searchString) {
        filterArray = listsData.filter(e => e.category === 'Timeline');  
        } 
    if (optionRange === 'unfinished') {
        filterArray = listsInProgressData;
    } 
    if (optionRange === 'All' && !searchString) {
        filterArray = listsData;  
        } 

    if (optionArtist) {
        filterArray = listsData.filter(e => e.artist === optionArtist.value);
        } 
    
    //console.table(filterArray);


    const ListItems = filterArray.map(value => {

        let page = '/' + value.page;

        return (
            <React.Fragment key={value.page}>

            <div className="row pt-3 pb-3 border-bottom border-top border-info">
                
                <div className="col-sm-3 pb-3 text-center">
                    
                    <div align="center">
                        {/* <img src={value.picture_link} alt="" height="175" width="175" /> */}
                        <img src={value.picture_link} alt="" className="img-fluid"></img>
                    </div>

                </div>

                <div className="col-sm-6 pb-3 border-right">
                    
                    <p className='Header'><b><Link to={page}>{value.name}</Link></b></p>
                       
                    <div className="factTextNoWrap rounded m-2 p-2">
                        <ReactMarkdown>
                            {value.description}                       
                        </ReactMarkdown>
                    </div>
                        

                </div>
              
                
                    <div className="col-sm-3 pb-4">

                        <div className="row">
                        
        
                            <div className="col pr-0 mr-2">
                                <div className="row">
                                    <div className="col-4 mr-2"><b>Category: </b></div>
                                    <div className="col-6 ml-2">{value.category}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4 mr-2"><b>Source: </b></div>
                                    <div className="col-6 ml-2">{value.source}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4 mr-2"><b>Year: </b></div>
                                    <div className="col-6 ml-2">{value.year}</div>
                                </div>
                                {
                                    (() => {
                                    if (value.publish_date) {
                                    return <div>
                                         <div className="row">
                                            <div className="col-4"><b>Published: </b></div>
                                            <div className="col-6">{value.publish_date}</div>
                                        </div>
                                     </div>
                                    }
                                    return
                                    })()
                                }

                                   
                                    <br /><br />
                                    {
                                        (() => {
                                        if (value.in_progress) {
                                        return  <b><span className="p-1 mb-2 bg-info text-white">In progress</span></b>
                                        }
                                        return
                                        })()
                                    }

                            </div>

                        </div>   
                    
                    </div>
                
                    

            </div>

            </React.Fragment>          
        )

    })
   

    return (
        <main className="my-1 py-1">
            {listsData.length > 0 && (
                <div className="container">

                 <SEO
                    title={'All Music Lists'}
                    description={'All kinds of ranked & unranked music lists; Albums, songs, concert, sessions, etc'}
                    name={'All Music Lists'}
                    url={'https://www.musicthisday.com/lists'}
                    picture_link={'https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/9935a7c0-106a-4bf7-ccd6-b5ec2e7feb00/width=500,height=500'}
                    canonical={'https://www.musicthisday.com/lists'}
                    type='article' />
                
                <h1>Music Lists</h1>
                
                <div className="row">
                        <div className="col-sm-3 mb-3">
                            <img src='https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/9935a7c0-106a-4bf7-ccd6-b5ec2e7feb00/width=500,height=500' 
                            alt="music_lists" className="img-fluid rounded mx-auto d-block" />
                            <br />
                            <b>Count: {filterArray.length}</b>
                        </div>

                        <div className="col-sm-5">
                                <h3>Music Lists</h3>
                                <p>
                                <strong>I love lists.</strong><br />
                                In this app I will publish some of my favorite lists, with filtering options & different view styles.<br />
                                <br />
                                Any suggestions? Drop me an email: egil@musicthisday.com
                                    </p>
                                <p><strong>click on list name to visit list.</strong></p>

                                <div className="mt-3">
                                    Please check out our <b>Facebook page</b> for daily updates:
                                    -> <b><a href='https://www.facebook.com/JohannasVisions'>Facebook</a></b>
                                </div>

                                
                        </div>
                        
                        <div className="col-sm-4 mb-4 border-start border-info">

                            {(!searchString)  &&
                                <div className="row">   
                                    <div className="col">
                                        <h5>Filters</h5>
                                    </div>
                                </div>
                            }

                            {(!searchString)  && 
                                <div className="row pb-2"> 
                                    <div className="col-6">
                                            <Select
                                                //styles={{control: customControlStyles}}
                                                value={optionArtist}
                                                onChange={selectedOptionArtist}
                                                options={ArtistOptions}
                                                placeholder={'select artist..'}

                                            />
                                    </div>

                                    <div className="col-6"> 
                                        <button type="button" className="btn btn-primary" onClick={() => setOptionArtist(null)}> Reset filter</button>
                                    </div> 

                                </div>
                            }

                            {(!optionArtist)  &&  
                                <div className="row mt-2">   
                                        <div className="col">
                                        <h5>Search lists</h5>
                                    </div>
                                </div>
                            }
                            {(!optionArtist)  &&  
                            <div className="row mb-1">  
                                <div className="col-sm-6 mt-1">
                                    <div className="searchMedium">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={500}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all lists" 
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-1">
                                    <button type="button" className="btn btn-primary"   
                                        onClick={() => clearSearchField()} > Reset search</button>
                                </div>

                            </div>
                            }
                            <div className="form-check mt-4">
                                <input
                                    type="checkbox"
                                    id="checkbox"
                                    checked={AgGrid}
                                    onChange={checkHandler}
                                    className="form-check-input"
                                />
                                <label htmlFor="checkbox">Change to Grid </label>
                            </div>

                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-sm-4" align="Left">
                            {optionRange === '' && 
                            <button type="button" className="btn btn-info btn-xs active focus mt-1" onClick={() => setOptionRange('All')}>All categories</button>
                            }
                            {optionRange !== '' && 
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('All')}>All categories</button>
                            }
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('People')}>People</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Albums')}>Albums</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Songs')}>Songs</button>
                            &nbsp;&nbsp;
                        </div>
                        <div className="col-sm-6" align="Left">
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Live Songs')}>Live Songs</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Concerts')}>Concerts</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Interviews')}>Interviews</button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('Timeline')}>Timeline</button>
                            &nbsp;&nbsp;
                            {(window.location.hostname === "localhost") &&
                            <button type="button" className="btn btn-info btn-sm mt-1" onClick={() => setOptionRange('unfinished')}>Lists in progress</button>
                            }
                            
                        </div>


                        <div className="col-sm-2 pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm mt-1" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    
                    {!AgGrid && 
                    <div className="row">
                        <div className="col-sm-12">      
                                 {ListItems} 
                        </div>
                    </div>
                    }

                    {AgGrid && 
                    <div className="row pr-2 pl-2 pt-3">
                        <div id="myGrid" style={{ height: '2000px', width: '1200px' }} className="ag-theme-balham">
                            <AgGridReact
                                rowSelection="multiple"
                                pagination={true}
                                rowData={filterArray}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                    </div>
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>

                </div>
                
            )}
        </main>
    )


}

export default Lists